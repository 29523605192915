.flex-cards {
    .center-content {
        text-align: center;
    }
    .row {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .col-sm-4, .col-md-4 {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 33%;
    }
    .col-md-6
    {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width:49.9%;
    }
    .panel {
        width: 100%;
        background-color: lighten(lightgrey, 10%);
    }
}

