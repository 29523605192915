.series-note {
  margin-bottom: 12px;
  .series-note--head {
    p {
      padding: 0;
      margin: 0;
    }
    padding: 6px;
    background: $grey-color-dark;
    color: $alt-color-lighter;
  }
  .series-note--content {
    ul {
      margin-bottom: 0;
    }
    border-left: dotted 4px $grey-color-dark;
    border-right: dotted 4px $grey-color-dark;
    padding: 6px;
  }
  .series-note--footer {
    padding-right: 6px;
    background: $grey-color-dark;
    text-align: right;
    .shout-out {
      text-align: right;
      color: lighten;
    }
  }
}
