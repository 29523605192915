.technology-card {
  background-color: lightgrey;
  border: none !important;
  // border: solid 1px #222 !important;
  margin-bottom: 12px;
  .technology-card--head {
    p, h1, h2,h3,h4,h5 {
      padding: 0;
      margin: 0;
    }
    text-align: center;
    padding: 6px;
    background: $brand-color-lighter;
    color: white;
  }
  .technology-card--icon {
    padding: 12px;
    text-align: center;
    background-color: #222;
    img {
      max-height: 140px;
    }
  }
  .technology-card--content {
    padding: 6px;
    text-align: center;
    // border: dotted 1px;
    // border-top: none;
  }
  .technology-card--footer {
    padding-right: 6px;
    background: $grey-color-light;
    text-align: right;
    .shout-out {
      text-align: right;
      color: lighten;
    }
  }
}

