@font-face {
   font-family: 'Exo';
   src: url("/fonts/static/Exo-Regular.ttf") format("truetype");
}

body {
  color: $grey-color-lighter;
}

h1.post-title {
  color: $alt-color-dark;
}

h1, h2, h3, h4, h5, hr {
  color: $alt-color-dark;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.1em;
}

#loadingSpinner {
  position: fixed;
  height: 100%;
  width: 100%;
  background: black;
  opacity: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
