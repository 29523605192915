@charset "utf-8";



// Our variables
$base-font-family: 'Exo', sans-serif;
// $base-font-family: Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #222;
$brand-color:      #5b2f2f;

$brand-color-lighter:  lighten($brand-color, 30%);
$brand-color-light:    lighten(desaturate(darkred, 50%), 60%);
// $brand-color:      #2a7ae2;

// $alt-color: #2a7ae2;
// $alt-color-lighter: lighten(desaturate($alt-color, 2%), 25%);
$alt-color: #98ff98;
$alt-color-lighter: lighten(desaturate($alt-color, 2%), 12%);
$alt-color-dark: darken(desaturate($alt-color, 80%), 5%);

$grey-color:       #828282;
$grey-color-lighter: lighten($grey-color, 10%);
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-mobi:          369px;
$on-palm:          600px;
$on-laptop:        800px;



// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: calc($spacing-unit / 2);
//         padding-left: calc($spacing-unit / 2);
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting",
        "custom",
        "components/_flex-cards",
        "components/_series-note",
        "components/_technology-card",
        "components/_share-bar"
;
